import axios from 'axios'

import router from '../router'

const service = axios.create({
    // baseURL: 'http://tdoctor.irealcare.com/',
    baseURL: 'https://doctor.irealcare.com/',
    timeout: 50000,
});
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        console.log(res);
        if (res.code === "1000") {
            router.push({
                path: "/noData",
                query: {
                    type: 404,
                },
            });
            return response;
        }
        if (res.status && res.status !== 200) {
            if (res.status === 500) {
                router.push({
                    path: "/noData",
                    query: {
                        type: 500,
                    },
                });
                return response;
            }
            return response;
        } else {
            return response;
        }
    },
    (error) => {
        return error;
    }
);
export default service;
