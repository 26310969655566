import axios from './request1'

// 安全用药信息
export const querySafeDrugCatalog = (rows, page, first, second) => axios.get('/doctor/acquire/drug/querySafeDrugCatalog', {
    params: {
        req: {
            rows,
            page,
            first,
            second,
        }
    }
})
// 药品详情
export const queryDrugInfo = (id) => axios.get('/doctor/acquire/drug/queryDrugInfo', {
    params: {
        req: {
            id
        }
    }
})
// 药品说明书列表
export const listDrugInstructionName = (rows, page, name) => axios.get('/doctor/acquire/drug/listDrugInstructionName', {
    params: {
        req: {
            rows,
            page,
            name
        }
    }
})
// 药品说明书详情
export const queryDrugInstructionDetail = (id) => axios.get('/doctor/acquire/drug/queryDrugInstructionDetail', {
    params: {
        req: {
            id
        }
    }
})